<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">STUDENT </span> STATUS
    </PageHeader>

    <v-row justify="center">
      <v-col cols="8">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title class="subtitle-1">
              Search Student by Index Number
            </v-toolbar-title>

            <v-autocomplete
              @change="studentStatus"
              :loading="loadStudent"
              v-model="index_number"
              :items="studentcampusBasket"
              :search-input.sync="searchStudent"
              @keyup="debouncesearchStudent"
              cache-items
              hide-details
              flat
              item-text="index_number_title_name"
              item-value="id"
              label="Index Number"
              solo-inverted
              class="mx-4"
              :rules="[(v) => !!v || 'Index number is required...']"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Search
                    <strong>Student...</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
            <v-btn
              v-if="ifstudentFound"
              :disabled="
                !getters_abilities.includes('update_student_status_access')
              "
              @click.stop="dialogstudentStatus = true"
              @click="
                showlatestStatus();
                comment = null;
              "
              icon
              ><v-icon x-large>mdi-plus-circle</v-icon></v-btn
            >
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>

    <v-scroll-y-transition>
      <v-row v-show="ifstudentFound">
        <v-col>
          <v-card>
            <v-data-table
              :headers="headers"
              :items="basketWithIndex"
              hide-default-footer
              class="elevation-1"
              item-key="id"
            >
              <template v-slot:[`item.student_current_status.year`]="{ item }">
                <template v-if="item.student_current_status.year > 0">
                  {{
                    item.calendar.startyear + item.student_current_status.year
                  }}
                  /
                  {{ item.calendar.endyear + item.student_current_status.year }}
                  {{ item.semester.semester }}
                </template>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-scroll-y-transition>

    <v-navigation-drawer
      fixed
      v-model="dialogstudentStatus"
      :permanent="dialogstudentStatus"
      right
      width="45%"
      class="shadow"
      clipped
      elevation-19
    >
      <div class="mt-15 d-flex justify-space-between">
        <v-spacer></v-spacer>
        <v-btn class="ma-2" @click="dialogstudentStatus = false" icon>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title class="subtitle grey--text text--darken-4">
              <span v-if="semester">Update Existing Student Status</span>
              <span v-else class="error--text text-wrap">
                Student Status Cannot Be Updated Because Header Is Not Mounted
              </span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col>
                  <v-select
                    hide-details="auto"
                    outlined
                    :items="otherStatusArray"
                    item-text="status"
                    item-value="id"
                    v-model="currentStatus"
                    label="Status"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="issemesterLoaded"
                    v-model="semester"
                    :items="basket_semester"
                    disabled
                    item-value="id"
                    item-text="semester"
                    outlined
                    label="Semester"
                    hide-details="auto"
                    hide-selected
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Semesters...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    hide-details="auto"
                    v-model="comment"
                    outlined
                    label="Comment"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <Response class="mb-5" v-if="response">
        <template v-slot:header>{{ msgHeader }}</template>
        <template v-slot:body>{{ msgBody }}</template>
        <template v-slot:icon>{{ msgIcon }}</template>
      </Response>

      <v-overlay absolute :value="overlay" opacity="1">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <template v-slot:append>
        <v-card-actions class="mb-4">
          <v-btn
            color="secondary darken-1"
            text
            @click="dialogstudentStatus = false"
          >
            CANCEL
          </v-btn>

          <v-btn
            :disabled="validateStatus"
            color="primary darken-1"
            @click="insertnewStatusBtn"
          >
            UPDATE
          </v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    provide,
    reactive,
    ref,
    toRefs,
    watch,
  } from "vue";
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import Response from "@/components/ActionResponse/Response";
  export default defineComponent({
    components: { PageHeader, Response },

    setup() {
      const vm = getCurrentInstance()
      const {
        getStudentCampus,
        getstudentStatusLog,
        getStudentStatus,
        insertnewStatus,
        getSemester,
        signOut,
        getStudentCurrentHeader,
      } = useActions([
        "getStudentCampus",
        "getstudentStatusLog",
        "getStudentStatus",
        "insertnewStatus",
        "getSemester",
        "signOut",
        "getStudentCurrentHeader",
      ]);

      const {
        getters_student_campus,
        user,
        getters_student_status_log,
        getters_student_status,
        getters_semester,
        update_status_status,
        getters_abilities,
      } = useGetters([
        "getters_student_campus",
        "user",
        "getters_student_status_log",
        "getters_student_status",
        "getters_semester",
        "update_status_status",
        "getters_abilities",
      ]);

      const searchStudentHeader = reactive({
        dialogstudentStatus: false,
        currentStatus: null,
        beforeStatus: null,
        loadStudent: false,
        index_number: null,
        studentcampusBasket: [],
        searchStudent: null,
        otherStatusArray: [],
        comment: "",
        validateStatus: true,
        semester: null,
        basket_semester: [],
        issemesterLoaded: false,
      });

      const studentstatusLog = reactive({
        response: false,
        studentLog: [],
        headers: [
          //{ text: "#", value: "index" },
          { text: "STATUS", value: "student_current_status.status" },
          { text: "ACADEMIC CALENDAR", value: "calendar.academic_calendar" },
          { text: "LEVEL", value: "level.level" },
          { text: "SEMESTER", value: "semester.semester" },
          {
            text: "EXPECTED RETURN",
            value: "student_current_status.year",
            align: "center",
          },
          { text: "COMMENTS", value: "comment" },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        color: "",
        overlay: false,
      });

      const {
        searchStudent,
        studentcampusBasket,
        loadStudent,
        beforeStatus,
        otherStatusArray,
        currentStatus,
        index_number,
        comment,
        validateStatus,
        dialogstudentStatus,
        basket_semester,
        issemesterLoaded,
        semester,
      } = toRefs(searchStudentHeader);

      const { msgHeader, msgBody, msgIcon, response, color, overlay } =
        toRefs(studentstatusLog);

      let ifstudentFound = ref(false);

      const studentStatus = (index_number) => {
        Promise.all([
          getStudentCurrentHeader(index_number),
          getstudentStatusLog(index_number)
            .then(() => {
              ifstudentFound.value = true;
            })
            .catch((e) => {
              response.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              color.value = "error";
              switch (e.response.status) {
                case 423:
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                  break;
                case 403:
                  msgBody.value = e.response.data.message;
                  break;
                default:
                  msgBody.value =
                    "Try Again Later Or Call The System Administrator";
                  break;
              }
            }),
        ]);
      };

      const debouncesearchStudent = debounce(() => {
        if (searchStudent.value) {
          if (studentcampusBasket.value.length > 0) return;
          loadStudent.value = true;

          const infoBasket = {
            role: user.value.role,
            campus: user.value.campus,
          };
          getStudentCampus(infoBasket)
            .then(() => {
              studentcampusBasket.value = getters_student_campus.value;
            })
            .finally(() => {
              loadStudent.value = false;
            })
            .catch((e) => {
              response.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              switch (e.response.status) {
                case 423:
                  msgBody.value = e.response.data.message;
                  color.value;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                  break;
                case 403:
                  msgBody.value = e.response.data.message;
                  break;
                default:
                  msgBody.value =
                    "Try Again Later Or Call The System Administrator";
                  break;
              }
            });
        }
      });

      const basketWithIndex = computed(() => {
        return getters_student_status_log.value.map((items /* , index */) => ({
          ...items,
          /* 	index: index + 1, */
        }));
      });

      const showlatestStatus = () => {
        overlay.value = true;
        getStudentStatus()
          .then(() => {
            const items = Array.from(getters_student_status.value);

            beforeStatus.value =
              getters_student_status_log.value[
                getters_student_status_log.value.length - 1
              ].current;

            semester.value =
              getters_student_status_log.value[
                getters_student_status_log.value.length - 1
              ].current;

            otherStatusArray.value = items.filter(
              (item) =>
                item.id !== beforeStatus.value &&
                !["Completed", "Graduated"].includes(item.status)
            );
          })
          .then(() => (overlay.value = false))
          .catch((e) => {
            response.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              case 403:
                msgBody.value = e.response.data.message;
                break;
              default:
                msgBody.value =
                  "Try Again Later Or Call The System Administrator";
                break;
            }
          });
      };

      const insertnewStatusBtn = () => {
        overlay.value = true;
        response.value = false;
        insertnewStatus({
          before: beforeStatus.value,
          current: currentStatus.value,
          student_id: index_number.value,
          user_id: user.value.id,
          comment: comment.value,
          semester: semester.value,
        })
          .then(() => {
            response.value = true;
            comment.value = "";
            currentStatus.value = "";
            semester.value = "";
            dialogstudentStatus.value = false;
            msgHeader.value = "Success";
            msgBody.value = "Student's Status Is Successfully Updated";
            msgIcon.value = "mdi-check-circle";
            color.value = "success";
          })
          .catch((e) => {
            response.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              case 403:
                msgBody.value = e.response.data.message;
                break;
              default:
                msgBody.value =
                  "Try Again Later Or Call The System Administrator";
                break;
            }
          })
          .finally(() => (overlay.value = false));
      };

      provide("color", color);

      const fetchSemester = () => {
        issemesterLoaded.value = true;
        if (getters_semester.value.length > 0) {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
          return;
        }

        getSemester().then(() => {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
        });
      };

      fetchSemester();

      watch(
        () => [comment.value, currentStatus.value /* semester.value */],
        () => {
          if (comment.value && currentStatus.value /* && semester.value */) {
            validateStatus.value = false;
            return;
          }

          validateStatus.value = true;
        }
      );

      return {
        studentStatus,
        debouncesearchStudent,
        ...toRefs(searchStudentHeader),
        ...toRefs(studentstatusLog),
        ifstudentFound,
        basketWithIndex,
        showlatestStatus,
        getters_student_status,
        insertnewStatusBtn,
        update_status_status,
        getters_abilities,
      };
    },
  });
</script>
<style>
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.082);
  }
</style>
